import React from 'react';
import './HomePage.css';

const HomePage = () => {
  return (
    <div className="homepage">
      <div className="homepage-container">
        <h1 className="homepage-title">Welcome to my site</h1>
        <p className="homepage-description">
          Discover our amazing tools, blogs, and services tailored just for you.
        </p>
      </div>
    </div>
  );
};

export default HomePage;
